import React, { useState } from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// components
import { TestimonialCard }  from "../components/testimonial";

// images
import banner_img from "../images/partner.svg";
import monitor from '../images/partners_logor/1_monitor.jpg';
import jeeves from '../images/partners_logor/2_jeeves.jpg';
import proclient from '../images/partners_logor/proclient.jpg';
import zoezi from '../images/partners_logor/10_zoezi.jpg';
import trinax from '../images/partners_logor/13_trinax.jpg';
import gc_solution from '../images/partners_logor/9_gc.jpg';
import vagfas from '../images/partners_logor/vagfas.jpg';
import work_system from '../images/partners_logor/work_system.jpg';
import lr_logo from '../images/partners_logor/lr.jpg';
import swinx from '../images/partners_logor/5_swinx.jpg';
import docup from '../images/partners_logor/7_docup.jpg';
import kr_system from '../images/partners_logor/kr_system.jpg';

const BOT_CODE = "kxFV5wCG";

function Partner() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Partner | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflow has a complete partner program for you who want to be able to deliver the best invoicing services on the market to your customers." />
                <meta name="keywords" content="invoicing services | Crediflow partner program" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Partner program for those who want to be able to deliver the best invoice services on the market." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.optosweden.com/partner" />
                <link rel="cannonical" href="https://www.optosweden.com/partner" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Do you want to be able to deliver the best invoicing services on the market to your customers?</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Crediflow has a complete partner program both for 
                                        integrators as well as pure resellers. With modern 
                                        APIs, you can present our solution as your own, or 
                                        use our partner sellers to get the support 
                                        needed to get your business off the ground.
                                        <br/><br/>
                                        Contact us and we will see how together we can offer your customers the best solution!
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-full p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                           

                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>


            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">This is what our partners think!</h1>
                    <p className="mt-4 text-center text-gray-600">Do like others, experience the wholeness!</p>
                    <div className="mt-14 pb-32">
                        <div className="grid  lg:grid-cols-2 xl:grid-cols-3 gap-8">
                            <TestimonialCard  company="Monitor ERP System AB" logo={monitor}>
                                Crediflow's solution CrossState provides a fully 
                                integrated digital invoice flow in MONITOR and 
                                helps our customers further streamline their operations.
                                <br/><br/>
                                The service for invoice interpretation and receipt of 
                                e-invoices is easily activated directly in MONITOR 
                                and means that our customers now save precious time 
                                that they can spend on more value-creating activities.
                                <br/><br/>
                                The collaboration with Crediflow, a responsive and 
                                committed supplier, provides high quality in our 
                                final product and is an important part of maintaining 
                                a world-class business system.
                            </TestimonialCard>

                            <TestimonialCard  company="Jeeves Information Systems AB" logo={jeeves}>
                                Together with Crediflow, we at Jeeves can help our 
                                customers increase their productivity by spending 
                                their time on value-creating and developing tasks.
                                <br/><br/>
                                How do we do it? Well, by automating important 
                                financial flows such as incoming and outgoing 
                                invoices where Crediflow's product CrossState 
                                and Jeeves ERP solve the flow quickly and 
                                painlessly for the user. When invoices and 
                                purchase orders match each other, we together 
                                create a fully automated flow all the way to payment.
                                <br/><br/>
                                Thanks to Crediflow's solid competence, we can 
                                offer our customers a service that includes 
                                both a good product and a fantastic team behind the levers.
                            </TestimonialCard>

                            <TestimonialCard  company="Proclient System AB" logo={proclient}>
                                In today's digital society, more and more demands are 
                                placed on automated solutions and smart integrations.
                                <br/><br/>
                                Since 2014, together with Crediflow, Proclient 
                                has delivered a valued service to customers all 
                                over the country that streamlines their invoice 
                                handling. With CrossState eVoyce and our Plus 
                                function, our customers get the invoice registered 
                                and posted right into Pyramid.
                                <br/><br/>
                                Customers can also opt for external processing, 
                                which means that the first time they see the 
                                invoice, it is already posted and ready inside Pyramid.
                            </TestimonialCard>

                            <TestimonialCard company="KR System" logo={kr_system}>
                                Based on feedback and requests from our customers, 
                                together with Crediflow we have developed a unique 
                                platform in Pyramid Business Studio for digital invoice 
                                flow that combines the best of both worlds:

                                <br/>- Industry-leading interpretation technology through AI and Machine Learning technology
                                <br/>- Retained full control, with certificate and accounting directly in Pyramid
                                <br/><br/>
                                Efficient, scalable and in an interface designed for 
                                speed. Read more about KR System's solution 
                                Electronic invoice management (EFH)
                                <br/><br/>
                                We at KR System have over 500 customers under 
                                our management where we help streamline and 
                                simplify processes on a daily basis. Crediflow's 
                                solid competence and commitment means that 
                                together we can offer our customers a good 
                                product and a strong team.
                            </TestimonialCard>

                            <TestimonialCard  name="Mikael Håkansson" company="Zoezi AB" logo={zoezi}>
                                Achieving a flexible solution where invoices are 
                                automatically interpreted and entered directly 
                                into the Zoezi business system where they can 
                                be easily checked off and then confirmed for 
                                automatic payment is incredibly important to us and our customers.
                                <br/><br/>
                                Thanks to this, customers can minimize administrative 
                                time and spend it on their core business instead. 
                                The collaboration with Crediflow has worked very 
                                well, we get a quick response when we have 
                                questions and have received support throughout 
                                the development work.
                            </TestimonialCard>

                            <TestimonialCard  name="Fredrik Yli-Luopa" company="Trinax AB" logo={trinax}>
                                In Trinax Time &amp; Project, our customers can create 
                                quotes, time reports, invoices, project follow-up 
                                and now with our integrated solution together with 
                                Crediflow, we can also offer a complete solution 
                                for incoming and outgoing invoices.
                                <br/><br/>
                                This makes the work for our customers very efficient.
                            </TestimonialCard>

                            <TestimonialCard  name="Mathias Bohlin" company="GC Solutions AB" logo={gc_solution}>
                                Together with Crediflow, we have created a 
                                seamless integration between Garp and one 
                                of the market's best supplier invoicing systems, 
                                just right in time, we think, as this type of 
                                solution is increasingly in demand, so that our 
                                customers are given an efficient and location-independent 
                                control, monitoring and attestation process.
                            </TestimonialCard>

                            <TestimonialCard  name="Sebastian Öberg" company="Vägfas AB" logo={vagfas}>
                                Crediflow are competent and pleasant to 
                                work with, always willing to make adjustments 
                                and offer assistance.
                                <br/><br/>
                                We have been very satisfied customers for 
                                4 years, simply a smooth and great 
                                supplier of prints and e-invoices.
                            </TestimonialCard>

                            <TestimonialCard company="At Work Systems AS" logo={work_system}>
                                As a development partner, we benefit from their extensive 
                                expertise and experience so that we can jointly 
                                develop the best solutions for our various customer groups.
                            </TestimonialCard>

                            <TestimonialCard  name="Martin Johansson" company="LR Revision &amp; Redovisning" logo={lr_logo}>
                                We were looking for a partner who partly 
                                has very high competence in interpretation, 
                                and partly can be a strategic partner regarding 
                                the automation and digitization of our services.
                            </TestimonialCard>

                            <TestimonialCard company="Swinx AB" logo={swinx}>
                                What Crediflow offers in terms of technology, 
                                security, availability and support is top 
                                class and invaluable to us as a partner.
                            </TestimonialCard>

                            <TestimonialCard company="DocUp AB" logo={docup}>
                                What DocUp offers in terms of flexibility 
                                and efficiency to our users would not 
                                have been possible without Crediflow's 
                                services and API integration.
                            </TestimonialCard>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Partner;